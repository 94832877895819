import React from 'react';
import Highlight from 'react-highlight';

const Modal: React.FC = () => {

    return (
        <div>
            <h1 className="mb-s">Modal</h1>
            <p className="mb-l">
                The Modal component is a dialog box/popup window that is displayed on top of the current page. See example <a href='/demo/modal' title="Modal example"><strong>here</strong></a>.
            </p>

            <h2 className="mb-m">Modal components:</h2>
            <p className="mb-s"><strong><a href="#modal">Modal</a></strong> - Wrapper for the modal content.</p>
            <p className="mb-xl"><strong>ModalScrollableContainer</strong> - Wrapper component which enables scroll when content is longer than the viewport height. Should be placed at the bottom of <strong>Modal</strong>.</p>


            <Highlight className="React">
                {
                    `
import React from 'react';
import { Modal, Button } from '@jkhy/vsg-loanvantage-design-system';
class Example extends React.Component {
    state = {
        modalS: false,
        modalM: false,
        modalMPlus: false,
        modalL: false,
        modalLPlus: false,
        modalXL: false,
        modalLongContent: false,
        modalScrollable: false
    }; 
    render() {
        return (
            <div>

                <!-- Modals are toggled through the isVisible prop. -->
                <Button dataUI={getDataUI()} icon="fal fa-pastafarianism"
                    onClick={() => this.setState({ modalS: true })}>Modal</Button>

                <Modal dataUI={getDataUI()} title="Modal size S" isVisible={this.state.modalS} 
                    size="S" onClose={() => this.setState({ modalS: false })}>
                </Modal>
                <Modal dataUI={getDataUI()} dataUI={getDataUI()} title="Modal size M" isVisible={this.state.modalM}
                    size="M" onClose={() => this.setState({ modalM: false })}>
                </Modal>
                <Modal dataUI={getDataUI()} title="Modal size M+" isVisible={this.state.modalMPlus}
                    size="M+" onClose={() => this.setState({ modalMPlus: false })}>
                </Modal>
                <Modal dataUI={getDataUI()} title="Modal size L" isVisible={this.state.modalL}
                    size="L" onClose={() => this.setState({ modalL: false })}>
                </Modal>
                <Modal dataUI={getDataUI()} title="Modal size L+" isVisible={this.state.modalLPlus}
                    size="L+" onClose={() => this.setState({ modalLPlus: false })}>
                </Modal>
                <Modal dataUI={getDataUI()} title="Modal size XL" isVisible={this.state.modalXL}
                    size="XL" onClose={() => this.setState({ modalXL: false })}>
                </Modal>

                <Modal dataUI={getDataUI()} title="Modal" isVisible={this.state.modalLongContent} 
                    size="L" onClose={() => this.setState({ modalLongContent: false })}>
                    <ModalScrollableContainer>
                        Long content...
                    </ModalScrollableContainer>
                </Modal>

                <Modal dataUI={getDataUI()} title="Modal" isVisible={this.state.modalScrollable} 
                    size="XL" onClose={() => this.setState({ modalScrollable: false })}>
                    <h1>Static</h1>
                    <h2 className="mb-xl">And more static</h2>
                    <div className="flex-full-height">
                        <>
                            <ModalScrollableContainer>
                                Long content...
                            </ModalScrollableContainer>
                        </>
                    </div>
                </Modal>
            </div>
        );
    }
}
export default Example;
        `}
            </Highlight>

            <h2 className="mb-m">API</h2>

            <h3 id="modal">Modal</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the title for modal.</td>
                    </tr>
                    <tr>
                        <td>isVisible</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Toggles the modal.</td>
                    </tr>
                    <tr>
                        <td>isHidden</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Keeps the modal invisible, but it is still rendered in the DOM.</td>
                    </tr>
                    <tr>
                        <td>id</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the id.</td>
                    </tr>
                    <tr>
                        <td>size</td>
                        <td><div style={{ whiteSpace: 'nowrap' }}>'S' | 'M' | 'M+' | 'L' | 'L+' | 'XL'</div></td>
                        <td>'M'</td>
                        <td>Sets the size for the modal.</td>
                    </tr>
                    <tr>
                        <td>isResizable</td>
                        <td>boolean</td>
                        <td>true</td>
                        <td>You can enable or disable the Modal resizing.</td>
                    </tr>
                    <tr>
                        <td>isHeightExpanded</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Explicitly expands the Modal to its maximum height.</td>
                    </tr>
                    <tr>
                        <td>observeIsHeightExpandedChange</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Explicitly observe should Modal expands to maximum height. For example: When having Modal with tabs in it and when specific tab is active to expand modal</td>
                    </tr>
                    <tr>
                        <td>isDraggable</td>
                        <td>boolean</td>
                        <td>true</td>
                        <td>You can enable or disable the Modal dragging.</td>
                    </tr>
                    <tr>
                        <td>onClose</td>
                        <td>Function</td>
                        <td className="text-center">-</td>
                        <td>Callback to execute when the modal is closed.</td>
                    </tr>
                    <tr>
                        <td>disableCloseBtn</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Set disabled state to Modal close button.</td>
                    </tr>
                    <tr>
                        <td>shouldReturnFocusAfterClose</td>
                        <td>boolean</td>
                        <td>true</td>
                        <td>Indicating if the modal should restore focus to the element
                            that had focus prior to its display.</td>
                    </tr>
                    <tr>
                        <td>confirmationDialog</td>
                        <td>
                            {`{
                                title?: string,
                                show?: boolean,
                                render?: Function,
                                onClose? (): void,
                            }`}
                        </td>
                        <td className="text-center">-</td>
                        <td>Enables a confirmation dialog that appears when attempting to close the modal.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

export default Modal;
