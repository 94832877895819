import React, { useState } from 'react';
import { Button, Checkbox, Col, ConfirmationDialog, Container, Datepicker, Input, Label, Modal, ModalScrollableContainer, Row, Select, Toolbar, Tooltip } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../helpers/helpers';


const DemoRelatedAgents = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isCheck, setIsCheck] = useState(true);
    const [addOrEditModal, setAddOrEditModal] = useState({ type: '', index: -1 });
    const [agents, setAgents] = useState<{ type: string; name: string; isSaved: boolean; show: boolean }[]>([]);
    const [newAgent, setNewAgent] = useState<{ type: string; name: string; }>({ type: '', name: '' });
    const [selectOptionsName, setSelectOptionsName] = useState<{ value: string; label: string }[]>([
        { value: '1', label: 'JTR Insutries' },
        { value: '2', label: 'Another Agent' },
    ]);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const selectOptionsType = [
        { value: '1', label: 'Accountant' },
        { value: '2', label: 'Type 2' },
    ]

    const addAgent = () => {
        setAgents([
            ...agents,
            {
                type: '',
                name: '',
                isSaved: false,
                show: false,
            },
        ]);
    };

    const updateAgent = (index: number, key: string, value: string | boolean) => {
        const updatedAgents = agents.map((agent, i) =>
            i === index ? { ...agent, [key]: value } : agent
        );
        setAgents(updatedAgents);
    };

    const removeAgent = (index: number) => {
        const updatedAgents = agents.filter((_, i) => i !== index);
        setAgents(updatedAgents);
    };

    const toggleDialog = (index: number, show: boolean) => {
        updateAgent(index, 'show', show)
    };

    const onCloseAgentsModal = () => {
        if (agents.some(agent => !agent.isSaved)) {
            setShowConfirmationDialog(true);
        } else {
            setIsModalVisible(false);
        }
    }

    const onCloseConfirmationDialog = () => {
        const updatedAgents = agents.filter(agent => agent.isSaved);
        setAgents(updatedAgents);
        setShowConfirmationDialog(false);
        setIsModalVisible(false);
    }

    const onCloseModal = () => {
        setAddOrEditModal({ type: '', index: -1 });
        setNewAgent({ type: '', name: '' });
    }

    const onSaveModal = () => {
        agents[addOrEditModal.index] = { type: newAgent.type, name: selectOptionsName.length + 1 + '', isSaved: false, show: false };
        setSelectOptionsName([...selectOptionsName, { value: selectOptionsName.length + 1 + '', label: newAgent.name }]);

        onCloseModal();
    }

    return (
        <>
            <Button className="width-l" onClick={() => setIsModalVisible(true)} dataUI={getDataUI()}>Open Related Agents Modal</Button>

            <Modal
                title="Related Agents"
                isVisible={isModalVisible}
                size="M"
                onClose={onCloseAgentsModal}
                confirmationDialog={{
                    title: 'Unsaved Agents',
                    show: showConfirmationDialog,
                    render: () => <>
                        <p className="mb-m">You have unsaved agents. If you close this modal without saving, unsaved agent will be lost. Are you sure you want to proceed?</p>
                        <Toolbar dataUI={getDataUI()} leftSide={
                            <>
                                <Button onClick={onCloseConfirmationDialog} btnType="primary" icon='far fa-times' className="mr-s" dataUI={getDataUI()}>Yes, close</Button>
                                <Button onClick={() => setShowConfirmationDialog(false)} btnType="secondary" icon='far fa-times-circle' dataUI={getDataUI()}>Cancel</Button>
                            </>}
                        />
                    </>,
                    onClose: () => setShowConfirmationDialog(false),
                }}
                dataUI={getDataUI()}
            >
                <Toolbar className="mb-m" dataUI={getDataUI()} leftSide={(
                    <>
                        <Button icon="fal fa-plus" onClick={addAgent} dataUI={getDataUI()}>Add Related Agent</Button>
                    </>
                )}>
                </Toolbar>


                {agents.length <= 0 &&
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '172px' }}>
                        <p className="lv-small text-muted text-center">There are no related agents added yet. Click ‘Add Related Agent to start adding agents.</p>
                    </div>
                }

                {agents.length > 0 &&
                    <ModalScrollableContainer>
                        <Container style={{ minHeight: '172px' }} gap="S" flexColumn dataUI={getDataUI()}>
                            {agents.map((agent, index) => (
                                <Container key={index} padding="S" radius="small" isBorder dataUI={getDataUI()}>
                                    {agent.isSaved ?
                                        <div className="d-flex justify-content-between flex-1">
                                            <div className="d-flex">
                                                <Tooltip title="Edit Agent">
                                                    <Button onClick={() => { setAddOrEditModal({ type: 'Edit', index }); }} btnType="icon" icon="fal fa-pen" className="mr-s" dataUI={getDataUI()} />
                                                </Tooltip>
                                                <Label className="text-bold mr-xs" dataUI={getDataUI()}>{selectOptionsName[+agent.name - 1].label}</Label>
                                                <Label dataUI={getDataUI()}>({selectOptionsType[+agent.type - 1].label})</Label>
                                            </div>
                                            <ConfirmationDialog
                                                show={agent.show}
                                                render={() => <>
                                                    <p className="mb-m">Are you sure you want to remove this agent as related?</p>
                                                    <Toolbar
                                                        dataUI={getDataUI()}
                                                        leftSide={
                                                            <>
                                                                <Button
                                                                    dataUI={getDataUI()}
                                                                    onClick={() => {
                                                                        removeAgent(index);
                                                                    }}
                                                                    btnType="primary"
                                                                    icon="fal fa-times"
                                                                    className="mr-s"
                                                                >
                                                                    Yes, Remove
                                                                </Button>
                                                                <Button
                                                                    onClick={() => toggleDialog(index, false)}
                                                                    btnType="secondary"
                                                                    icon="far fa-times-circle"
                                                                    dataUI={getDataUI()}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </>
                                                        }
                                                    />
                                                </>}
                                                onClose={() => toggleDialog(index, false)}
                                                dataUI={getDataUI()}
                                            >
                                                <div>
                                                    <Tooltip title="Remove related agent">
                                                        <Button onClick={() => toggleDialog(index, true)} btnType="icon" icon="fal fa-times" dataUI={getDataUI()} />
                                                    </Tooltip>
                                                </div>
                                            </ConfirmationDialog>
                                        </div>
                                        :
                                        <>
                                            <Label className="mr-s" dataUI={getDataUI()}>Agent Type</Label>
                                            <Select
                                                dataUI={getDataUI()}
                                                className="flex-1 mr-m"
                                                options={selectOptionsType}
                                                value={agent.type || ''}
                                                placeholder=""
                                                onChange={(obj: any) => {
                                                    updateAgent(index, 'type', obj.value)
                                                }}
                                            />
                                            <Label className="mr-s" dataUI={getDataUI()}>Name</Label>
                                            <Tooltip title={agent.type === '' ? '' : "Add Agent"}>
                                                <Button onClick={() => { setAddOrEditModal({ type: 'Add', index }); }} btnType="icon" icon="fal fa-plus" className="mr-s" dataUI={getDataUI()} />
                                            </Tooltip>
                                            <Tooltip title={agent.type === '' || agent.name === '' ? '' : "Edit Agent"}>
                                                <Button onClick={() => { setAddOrEditModal({ type: 'Edit', index }); }} disabled={agent.type === '' || agent.name === ''} btnType="icon" icon="fal fa-pen" className="mr-s" dataUI={getDataUI()} />
                                            </Tooltip>
                                            <Select
                                                dataUI={getDataUI()}
                                                className="flex-1 mr-m"
                                                options={selectOptionsName}
                                                value={agent.name}
                                                placeholder=""
                                                disabled={agent.type === ''}
                                                onChange={(obj: any) => {
                                                    updateAgent(index, 'name', obj.value)
                                                }}
                                            />
                                            <Tooltip title={agent.type === '' || agent.name === '' ? '' : "Save"}>
                                                <Button disabled={agent.type === '' || agent.name === ''} onClick={() => { updateAgent(index, 'isSaved', true) }} btnType="icon" icon="fal fa-save" className="mr-s" dataUI={getDataUI()} />
                                            </Tooltip>
                                            <Tooltip title="Cancel">
                                                <Button onClick={() => { removeAgent(index); }} btnType="icon" icon="fal fa-times-circle" dataUI={getDataUI()} />
                                            </Tooltip>
                                        </>
                                    }
                                </Container>
                            ))}
                        </Container>
                    </ModalScrollableContainer>
                }
            </Modal>

            <Modal title={`${addOrEditModal.type} Agent`} isVisible={addOrEditModal.type !== ''} size="L" onClose={onCloseModal} dataUI={getDataUI()}>
                <Toolbar className="mb-m" dataUI={getDataUI()} leftSide={(
                    <>
                        {addOrEditModal.type === 'Edit' && <Button className="mr-s" icon="fal fa-save" dataUI={getDataUI()}>Save</Button>}
                        <Button onClick={onSaveModal} className="mr-s" icon="fal fa-save" dataUI={getDataUI()}>Save and Close</Button>
                        <Button onClick={onCloseModal} icon="fal fa-times-circle" btnType="secondary" dataUI={getDataUI()}>Cancel</Button>
                    </>
                )}>
                </Toolbar>

                <ModalScrollableContainer>
                    <Row>
                        <Col xs="6">
                            <Container className="mb-s" padding="M" radius="small" isBorder flexColumn dataUI={getDataUI()}>
                                <h2>Details</h2>
                                <hr className="mt-m mb-m" />
                                <Row className="mb-s">
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end">Agent Type</Label>
                                    </Col>
                                    <Col>
                                        {addOrEditModal.type === 'Edit' ?
                                            <Label dataUI={getDataUI()} className="text-bold">{selectOptionsType[+agents[addOrEditModal.index].type - 1].label}</Label>
                                            :
                                            <Select
                                                dataUI={getDataUI()}
                                                options={selectOptionsType}
                                                value={newAgent.type || ''}
                                                placeholder=""
                                                onChange={(obj: any) => {
                                                    setNewAgent({ ...newAgent, type: obj.value })
                                                }}
                                            />
                                        }
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end">(Agent Type) Name</Label>
                                    </Col>
                                    <Col>
                                        {addOrEditModal.type === 'Edit' ?
                                            <Input dataUI={getDataUI()} value={selectOptionsName[+agents[addOrEditModal.index].name - 1].label} />
                                            :
                                            <Input dataUI={getDataUI()} value={newAgent.name} onChange={(e) => { setNewAgent({ ...newAgent, name: e.target.value }) }} />
                                        }
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end">Code</Label>
                                    </Col>
                                    <Col>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end">Company</Label>
                                    </Col>
                                    <Col>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end">Salutation</Label>
                                    </Col>
                                    <Col>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end">License Number</Label>
                                    </Col>
                                    <Col>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end">License Expiration</Label>
                                    </Col>
                                    <Col>
                                        <Datepicker
                                            id="dp1"
                                            dataUI={getDataUI()}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end text-right">Company License Number</Label>
                                    </Col>
                                    <Col>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end">NMLS ID</Label>
                                    </Col>
                                    <Col>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end">Company NMLS ID</Label>
                                    </Col>
                                    <Col>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end">Website</Label>
                                    </Col>
                                    <Col>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end">Region</Label>
                                    </Col>
                                    <Col>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end ">Active</Label>
                                    </Col>
                                    <Col>
                                        <Checkbox dataUI={getDataUI()} onChange={() => { }} />
                                    </Col>
                                </Row>
                            </Container>

                            <Container padding="M" radius="small" isBorder flexColumn dataUI={getDataUI()}>
                                <h2>Contact Information</h2>
                                <hr className="mt-m mb-m" />
                                <Row className="mb-s">
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end">Business Phone</Label>
                                    </Col>
                                    <Col>
                                        <Select
                                            dataUI={getDataUI()}
                                            options={selectOptionsType}
                                            value=""
                                            placeholder=""
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end">Mobile Phone</Label>
                                    </Col>
                                    <Col>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end">Fax</Label>
                                    </Col>
                                    <Col>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end">Email</Label>
                                    </Col>
                                    <Col>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col xs="6">
                            <Container className="mb-s" padding="M" radius="small" isBorder flexColumn dataUI={getDataUI()}>
                                <h2>Physical Address</h2>
                                <hr className="mt-m mb-m" />
                                <Row className="mb-s">
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end">Address 1</Label>
                                    </Col>
                                    <Col>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end">Address 2</Label>
                                    </Col>
                                    <Col>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end">Zip</Label>
                                    </Col>
                                    <Col>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end">City</Label>
                                    </Col>
                                    <Col>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="4">
                                        <Label dataUI={getDataUI()} className="d-flex justify-content-end">State</Label>
                                    </Col>
                                    <Col>
                                        <Select
                                            dataUI={getDataUI()}
                                            options={selectOptionsType}
                                            value=""
                                            placeholder=""
                                        />
                                    </Col>
                                </Row>
                            </Container>

                            <Container padding="M" radius="small" isBorder flexColumn dataUI={getDataUI()}>
                                <h2>Mailing Address</h2>
                                <hr className="mt-m mb-m" />
                                <Toolbar className={isCheck ? "mb-m" : ''} dataUI={getDataUI()} leftSide={(
                                    <>
                                        <Checkbox checked={isCheck} onChange={() => { setIsCheck(!isCheck); }} dataUI={getDataUI()}>Mailing Address is the same as Physical Addres</Checkbox>
                                    </>
                                )}>
                                </Toolbar>
                                {isCheck &&
                                    <>
                                        <Row className="mb-s">
                                            <Col xs="4">
                                                <Label dataUI={getDataUI()} className="d-flex justify-content-end">Address 1</Label>
                                            </Col>
                                            <Col>
                                                <Input dataUI={getDataUI()} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs="4">
                                                <Label dataUI={getDataUI()} className="d-flex justify-content-end">Address 2</Label>
                                            </Col>
                                            <Col>
                                                <Input dataUI={getDataUI()} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs="4">
                                                <Label dataUI={getDataUI()} className="d-flex justify-content-end">Zip</Label>
                                            </Col>
                                            <Col>
                                                <Input dataUI={getDataUI()} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs="4">
                                                <Label dataUI={getDataUI()} className="d-flex justify-content-end">City</Label>
                                            </Col>
                                            <Col>
                                                <Input dataUI={getDataUI()} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="4">
                                                <Label dataUI={getDataUI()} className="d-flex justify-content-end">State</Label>
                                            </Col>
                                            <Col>
                                                <Select
                                                    dataUI={getDataUI()}
                                                    options={selectOptionsType}
                                                    value=""
                                                    placeholder=""
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </Container>
                        </Col>
                    </Row>
                </ModalScrollableContainer>
            </Modal>
        </>
    );
};

export default DemoRelatedAgents;